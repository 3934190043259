export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_ERROR = "SET_ERROR";
export const SET_LOADER = "SET_LOADER";
export const SET_PROFILE = "SET_PROFILE";

export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  };
};
export const setIsAuthenticated = (data) => {
  return {
    type: IS_AUTHENTICATED,
    payload: data,
  };
};
export const setError = (data) => {
  return {
    type: SET_ERROR,
    payload: data,
  };
};
export const setLoader = (data) => {
  return {
    type: SET_LOADER,
    payload: data,
  };
};
export const setProfile = (data) => {
  return {
    type: SET_PROFILE,
    payload: data,
  };
};
