import React from "react";
import { StyledAuthCard } from "../../styles/components";

const AuthCard = ({ style, isSummary, ...props }) => {
  return (
    <StyledAuthCard
      // className={styles.authCard}
      isSummary={isSummary}
      style={{
        ...style,
      }}
    >
      {props.children}
    </StyledAuthCard>
  );
};

export default AuthCard;
