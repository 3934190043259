/* eslint-disable import/no-anonymous-default-export */
import {
  SET_USER,
  IS_AUTHENTICATED,
  SET_LOADER,
  SET_ERROR,
} from "../actions/action.types";

const initialState = {
  user: null,
  loading: false,
  isAuthenticated: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case SET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
